


import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ProgramMediaBlock from '@/_modules/promo-program/components/program-media-block/program-media-block.vue';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import EwDocumentList from '@/_modules/promo-hall/components/ew-document-list/ew-document-list.vue';
import Tabs from '@/_ui/tabs/tabs.vue';
import { TTab } from '@/_ui/tabs/types/tabs.type';
import { ConferenceProgramChatType } from '@/_modules/promo-program/types/conference-program-chat-type.enum';
import ProgramChatWrapper from '@/_modules/promo-program-new/components/program-chat-wrapper/program-chat-wrapper.vue';
import { TContact } from '@/_types/contact.type';
import { QuestionTypes, TQuestionnaire, TQuestionnaireQuestionAnswerOption } from '@/_types/questionnaire.type';
import { TEvent } from '@/_types/event.type';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';
import _cloneDeep from 'lodash.clonedeep';
import questionnairesApi from '@/_api/questionnaires.api';
import Poll from '@/_modules/promo-program-new/components/poll/poll.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import IconFloatingProgramClose
  from '@/_modules/icons/components/floating-program-card/icon-floating-program-close.vue';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import iconShare from '@/_modules/icons/components/icon-ew-share.vue';
import IconBroadcastNoCircle from '@/_modules/icons/components/icon-broadcast-no-circle.vue';
import { TOpenEwSharerPayload } from '@/_store/ew-sharer.store';
import eventDiscoveryService, { TEventDiscoveryServiceConfig } from '@/_services/event-discovery.service';
import {Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ProgramSessionRating
  from '@/_modules/promo-program-new/components/program-session-rating/program-session-rating.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import Speaker from '@/_modules/promo-program-new/components/speaker/speaker.vue';
import IconOverlay from '@/_modules/icons/components/icon-overlay.vue';
import { TAddFloatingProgramCardParams } from '@/_modules/promo-program/store/promo-program.store';
import DateTimeHelper from '@/_helpers/date-time.helper';
import PromoBroadcastMenu from '@/_modules/promo/components/promo-broadcast-menu/promo-broadcast-menu.vue';
import EventHelper from '@/_helpers/event.helper';
import {TimeStatus} from '@/_types/time-status.enum';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
// @ts-ignore // TODO: add types or rewrite Statistics
import Statistics from '@/services/statistics';

enum StandaloneProgramPageChatTabNameKeys {
  CHAT = 'button.chat',
  QA = 'floatingProgramCard.tabs.questions',
  POLL = 'polls.tabTitle',
}

const beforeRouteEnter: { to: Route; from: Route; next?: NavigationGuardNext } = { to: null, from: null, next: null };

@Component({
  components: {
    ProgramMediaBlock,
    EwAvatar,
    EwDocumentList,
    Tabs,
    ProgramChatWrapper,
    Poll,
    SimplePopup,
    IconFloatingProgramClose,
    iconUnFavorite,
    iconFavorite,
    iconShare,
    IconBroadcastNoCircle,
    Speaker,
    IconOverlay,
    ProgramSessionRating,
    EwButton,
    PromoBroadcastMenu,
  }
})
export default class PromoProgramPage extends Vue {

  @Getter('promoProgramStore/getProgramById') getProgramById: (programId: number) => TConferenceProgram;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') myself: TContact;
  @Action('promoProgramStore/toggleFavorite') toggleFavorite: (params: {
    eventId: number;
    conferenceRoomId: number;
    programId: number;
    isFavorite: boolean;
  }) => Promise<void>;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string }) => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('promoProgramStore/addFloatingProgram') addFloatingProgramCard: (params: TAddFloatingProgramCardParams) => void;

  private destroyed$: Subject<void> = new Subject<void>();
  public isServiceConnected: boolean = false;
  public ConferenceProgramChatType: typeof ConferenceProgramChatType = ConferenceProgramChatType;
  public chatTabIndexes: { [key: string]: number } = {}; // N.B.: because we do not have some TTab.alias, and chatTabList is dynamic here
  public chatTabList: TTab[] = [];
  public questionnaires: TQuestionnaire[] = [];
  public isPollSentPopupVisible: boolean = false;
  public isRatingPopupVisible: boolean = false;
  public isPromoBroadcastMenuVisible: boolean = false;

  public readonly BroadcastType: typeof BroadcastType = BroadcastType;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get programId(): number {
    return this.$route.params.programId ? parseInt(this.$route.params.programId, 10) : null;
  }

  public get program(): TConferenceProgram {
    if (!this.programId) {
      return null;
    }
    return this.getProgramById(this.programId);
  }

  public get isProgramLoading(): boolean {
    return !this.program || !this.program.id;
  }

  public get mainPoll(): TQuestionnaire {
    if (!this.questionnaires || !this.questionnaires.length) {
      return null;
    }

    let result = Object.assign({}, this.questionnaires[0]);
    result.questions = result.questions ? result.questions.filter((question): boolean => question.is_enabled) : [];

    result = this.sortRatingOptions(result);

    return result;
  }

  public get activeTabIndex(): number {
    return this.chatTabList.findIndex(tab => tab.isActive === true);
  }

  public get isChatTabActive(): boolean {
    return this.activeTabIndex === this.chatTabIndexes[this.$t(StandaloneProgramPageChatTabNameKeys.CHAT) as string];
  }

  public get isQuestionsTabActive(): boolean {
    return this.activeTabIndex === this.chatTabIndexes[this.$t(StandaloneProgramPageChatTabNameKeys.QA) as string];
  }

  public get isPollTabActive(): boolean {
    return this.activeTabIndex === this.chatTabIndexes[this.$t(StandaloneProgramPageChatTabNameKeys.POLL) as string];
  }

  public get isEventOwner(): boolean {
    if (!this.myself || !this.event) {
      return false;
    }

    return this.event.creator_user_id === this.myself.user_id;
  }

  public get isSelectedDateToday(): boolean {
    if (!this.program.date_start) {
      return false;
    }
    const today = new Date();
    const selectedDate = new Date(this.program.date_start);
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);
    return today.getTime() === selectedDate.getTime();
  }

  public get formattedDate(): string {
    return (this.program.date_start && this.$moment(this.program.date_start).format('DD MMMM YYYY')) || '';
  }

  public get formattedTime(): string {
    const startTime = DateTimeHelper.dateToHoursAndMinutes(new Date(this.program.date_start)) || '';
    const endTime = DateTimeHelper.dateToHoursAndMinutes(new Date(this.program.date_end)) || '';
    return `${startTime} - ${endTime}`;
  }

  @Watch('program', { immediate: true })
  public onProgramChange(newValue: TConferenceProgram): void {
    if (newValue) {
      this.getProgramQuestionnaires();
      this.subscribeToProgramSessionTopic();
    }
  }

  @Watch('program.show_live_chat', { immediate: true })
  public onShowLiveChatChange(): void {
    this.initChatTabs();
  }

  @Watch('program.show_speaker_chat', { immediate: true })
  public onShowSpeakerChatChange(): void {
    this.initChatTabs();
  }

  @Watch('isServiceConnected', { immediate: true })
  private onIsServiceConnectedChange(): void {
    if (this.isServiceConnected && this.programId) {
      this.subscribeToProgramSessionTopic();
    }
  }

  public mounted(): void {
    this.countProgramSessionView();
    this.subscribeToServiceEvents();
  }

  public beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  }

  public beforeDestroy(): void {
    this.unSubscribeFromProgramSessionTopic();
  }

  private subscribeToServiceEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;
  }

  public subscribeToProgramSessionTopic(): void {
    if (eventDiscoveryService.isConnected()) {
      eventDiscoveryService.subscribeToProgramSessionTopic(this.programId);
    }
  }

  public unSubscribeFromProgramSessionTopic(): void {
    eventDiscoveryService.unSubscribeFromProgramSessionTopic(this.programId);
  }

  public initChatTabs(): void {

    this.chatTabIndexes = {};
    this.chatTabList = [];

    if (!this.program) {
      return;
    }

    if (this.program && this.program.show_live_chat) {
      this.chatTabList.push({
        title: this.$t(StandaloneProgramPageChatTabNameKeys.CHAT),
        isActive: false,
        index: this.chatTabList.length,
        extraClasses: 'tab-chat',
      });
    }

    if (this.program && this.program.show_speaker_chat) {
      this.chatTabList.push({
        title: this.$t(StandaloneProgramPageChatTabNameKeys.QA),
        isActive: false,
        index: this.chatTabList.length,
        extraClasses: 'tab-questions',
      });
    }

    this.updateChatTabIndex();

    if (this.chatTabList.length) {
      this.onActiveChatTabUpdate(this.chatTabList[0], 0);
    }
  }

  public onActiveChatTabUpdate(tab: TTab, index: number): void {
    this.chatTabList.forEach((item, idx) => {
      item.isActive = idx === index;
    });
  }

  public getSpeakerNameHTML(speaker: TContact): string {
    return [
      ((speaker && speaker.name) || '').trim().replace(/</g, '&lt;'),
      ((speaker && speaker.surname) || '').trim().replace(/</g, '&lt;'),
    ].join('<br />');
  }

  public onSpeakerClick(speaker: TContact): void {
    this.openContactCard({
      contactId: speaker.id,
    });
  }

  private countProgramSessionView(): void {
    if (this.isEventOwner) {
      return;
    }
    Statistics.programSessionView({
      eventId: this.eventId,
      programSessionId: this.programId,
    }, beforeRouteEnter);
  }

  private sortRatingOptions(poll: TQuestionnaire): TQuestionnaire {
    // For AW-2365 sort all RATING options by parseInt(.text, 10) ascending
    const result = _cloneDeep(poll);
    result.questions
      .filter(q => q.question_type === QuestionTypes.RATING)
      .forEach(q => {
        const sortedAnswerOptions: TQuestionnaireQuestionAnswerOption[] = [];
        const optionsOrdering: string[] = '1,2,3,4,5,6,7,8,9,10'.split(',');
        for (let i = 1; i <= optionsOrdering.length; i++) {
          const option = q.answer_options.find((a) => a.text === optionsOrdering[i - 1]);
          if (option) {
            sortedAnswerOptions.push(option);
          }
        }
        q.answer_options = _cloneDeep(sortedAnswerOptions);
      });
    return result;
  }

  private async getProgramQuestionnaires(): Promise<void> {
    if (!this.program && !this.program.id) {
      return;
    }

    try {
      const result: TQuestionnaire[] = await questionnairesApi.getProgramQuestionnaires({
        eventId: this.eventId,
        programId: this.program.id
      });
      if (result) {
        this.questionnaires = result;

        if (this.program && this.mainPoll && !this.chatTabList.find(tab => tab.title === this.$t(StandaloneProgramPageChatTabNameKeys.POLL))) {
          this.chatTabList.push({
            title: this.$t(StandaloneProgramPageChatTabNameKeys.POLL),
            isActive: false,
            index: this.chatTabList.length,
            extraClasses: 'tab-polls tab-invisible',
          });
        }

        this.updateChatTabIndex();
      }
    } catch {
      this.questionnaires = [];
    }
  }

  public updateChatTabIndex(): void {
    this.chatTabList.forEach(tab => {
      this.chatTabIndexes[tab.title as string] = tab.index;
    });
  }

  public onPollHasBeenSent(): void {
    this.showPollSentPopup();
  }

  public showPollSentPopup(): void {
    this.isPollSentPopupVisible = true;
  }

  public hidePollSentPopup(): void {
    this.isPollSentPopupVisible = false;
  }

  public onShareClick(event: Event): void {
    this.openSharer({
      eventTarget: (event.target as Element),
      url: (this.program && (location.origin + this.$router.resolve(this.$route).href)) || location.origin,
    });
  }

  public onToggleFavoriteClick(): void {
    if (!this.program) {
      return;
    }

    this.toggleFavorite({
      eventId: this.eventId,
      conferenceRoomId: this.program.conference_id,
      programId: this.program.id,
      isFavorite: !this.program.is_favorite,
    });
  }

  public onCloseClick(): void {
    try {
      this.$router.push({ name: 'promo-program' });
    } catch { /* ignore */ }
  }

  public makeAnOverlay(e: PointerEvent): void {
    const left: number = e.clientX - 400;
    const top: number = e.clientY + 100;
    this.addFloatingProgramCard({
      top,
      left,
      program: this.program,
    });

    this.onCloseClick();
  }

  public closeRatingPopup(): void {
    this.isRatingPopupVisible = false;
  }

  public showRatingPopup(): void {
    this.isRatingPopupVisible = true;
  }

  public onPollButtonClick(): void {
    const pollTab: TTab = this.chatTabList.find(tab => tab.title === this.$t(StandaloneProgramPageChatTabNameKeys.POLL));
    if (!pollTab) {
      return;
    }
    this.onActiveChatTabUpdate(pollTab, pollTab.index);
  }

  public onActionBroadcastClick(event: MouseEvent): void {
    event.stopPropagation();

    this.isPromoBroadcastMenuVisible = !this.isPromoBroadcastMenuVisible;

    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST || eventTimeStatus === TimeStatus.FUTURE) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
    }
  }
}
